

























































































import './styles/ProjectCard.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IStatus, IUserProject } from '@store/modules/user-projects/Interfaces';
import { IUserState } from '@store/modules/user/Interfaces';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { namespace } from 'vuex-class';
import { IProject } from '@store/modules/project/Interfaces';
import {ProjectDocumentsActions} from "@store/modules/project-documents/Types";
import {IDocument} from "@store/modules/project-documents/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSUserProjects = namespace('storeUserProjects');
const NSAboutProject = namespace('storeAboutProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'ProjectCard',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        ProjectCardInfoBlock: () => import('./ProjectCardInfoBlock.vue'),
        BaseLink: () => import('@components/BaseLink/BaseLink.vue'),
    },
})
export default class ProjectCard extends Vue {

    @Prop({ default: () => ({}) }) project!: IUserProject;
    @Prop({ default: 'normal' }) form!: 'normal' | 'small' | 'row';
    @Prop({}) viewMode!: string;

    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUserProjects.Getter('projectStatuses') projectStatuses!: IStatus[];
    @NSWorkList.Mutation(WorkListMutations.M_UNSET_CONSTRUCT_PACKS) unsetConstructPacks;
    @NSAboutProject.Action(AboutProjectActions.A_CHANGE_PROJECT_STATUS) changeProjectStatus!: (payload: any) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_PROJECT_DOCUMENTS) setDocumentsInStore!: (docs: IDocument[]) => Promise<void>

    get addressAsParam() {
        return this.project.address ? this.project.address.replace(/[.,:;|\\_]/g, '').replaceAll(' ', '%20') : null;
    }

    get projectAddress(): string {
        return this.project.address ? this.project.address: 'Адрес не указан'
    }

    get isProjectOnPause(): boolean {
        return this.project.status === 'Приостановлен';
    }

    get engineerPartAccess() {
        return this.userData.userInfo && this.userData.userInfo.engineerPart;
    }

    get portalPartAccess() {
        return this.userData.userInfo && this.userData.userInfo.portalPart;
    }

    get projectCardViewModeClass() {
        return `project-card--${this.viewMode}`
    }

    goToProject() {
        this.setDocumentsInStore([]);
        this.$router.push({
            name: 'project-documents',
            params: { projectId: String(this.project.id) }
        });
        this.unsetConstructPacks();
    }

    resumeProject() {
        const statusInWork = this.projectStatuses.filter(status => status.title === 'Выполнение работ')[0];
        if (statusInWork) {
            this.changeProjectStatus({
                id: this.project.id,
                status: statusInWork,
            });
        }
    }
}
